import React, { useState, useCallback } from 'react';
import { Atom } from '@grammarly/focal';
import { Word } from "../things.dto";

import { WordCardSideTest } from './WordCardSideTest';
import { CardSideProps, CardHandlers } from './card-sides/CardSide';
import { Side0 } from './card-sides/Side0';
import { Side1 } from './card-sides/Side1';
import { Side2 } from './card-sides/Side2';
import { Side3 } from './card-sides/Side3';
import { Side4 } from './card-sides/Side4';

interface WordCardSideProps {
  wordAtom: Atom<Word>;
  cardSide: number;
  cardTestMode: boolean;
  setCardTestMode: (value: boolean) => void;
  cardHandlers: CardHandlers;
}

export const WordCardSide: React.FC<WordCardSideProps> = ({
  wordAtom,
  cardSide,
  cardTestMode,
  setCardTestMode,
  cardHandlers,
}) => {
  const word = wordAtom.get();

  const [testOptions, setTestOptions] = useState<string[]>([]);

  const generateNewTestOptions = useCallback((word: Word) => {
    const trueOption = word.ua.split('|')[Math.floor(Math.random() * word.ua.split('|').length)];
    return [...word.fake_ua, trueOption].sort(() => Math.random() - 0.5);
  }, []);

  const startTestMode = useCallback(() => {
    setTestOptions(generateNewTestOptions(word));
    setCardTestMode(true);
  }, [word, setCardTestMode, generateNewTestOptions]);

  const commonProps: CardSideProps = {
    word,
    wordAtom,
    cardHandlers,
    setCardTestMode: startTestMode,
    cardTestMode,
  };

  if (cardTestMode) {
    return (
      <WordCardSideTest 
        wordAtom={wordAtom} 
        setTestMode={setCardTestMode}
        testOptions={testOptions}
        cardHandlers={cardHandlers}
      >
        <Side0 {...commonProps} />
      </WordCardSideTest>
    );
  }

  switch (cardSide) {
    case 0:
      return <Side0 {...commonProps} />;
    case 1:
      return <Side1 {...commonProps} />;
    case 2:
      return <Side2 {...commonProps} />;
    case 3:
      return <Side3 {...commonProps} />;
    case 4:
      return <Side4 {...commonProps} />;
    default:
      return null;
  }
};
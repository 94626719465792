import {Connection, Doc, Op} from "sharedb/lib/client";
import {Atom} from "@grammarly/focal";
import {getDiff} from 'recursive-diff';
import {pairwise} from 'rxjs/operators'
import { diffToOps } from './diff-utils';

export type SubmitOp = (ops: Op[]) => void;

interface DocSubscription<T> {
  data: Atom<T | undefined>;
  submitOp: SubmitOp;
}

export class SessionAPI {
  private readonly subscriptionsCache = new Map<string, DocSubscription<unknown>>();
  private readonly docsSubscribed: Doc[] = [];

  public constructor(
    private _connection: Connection,
    private sessionId: string
  ) {
  }

  public getDocSubscription<T>(docId: string): DocSubscription<T> {
    // Check if the subscription already exists in the cache
    if (this.subscriptionsCache.has(docId)) {
      return this.subscriptionsCache.get(docId) as DocSubscription<T>;
    }

    // If not found, create a new subscription
    const newSubscription = this._getDocSubscription<T>(docId);

    // Store the new subscription in the cache
    this.subscriptionsCache.set(docId, newSubscription as DocSubscription<unknown>);

    return newSubscription;
  }

  private _getDocSubscription<T>(docId: string): DocSubscription<T> {
    console.log(`_getDocSubscription(${docId})`)
    let doc = this._connection.get(this.sessionId, docId);
    let docData = Atom.create<T | undefined>(undefined);
    let changeSource: 'server' | 'user' = 'user';

    doc.subscribe((err: unknown) => {
      if (err) return console.error("sharedDb error:", err);
      console.log(`got initial value for ${docId}`, doc.data)
      this.docsSubscribed.push(doc);
      changeSource = 'server'
      docData.set(structuredClone(doc.data));
      changeSource = 'user'

      doc.on("op", (op: unknown, _source: unknown) => {
        console.log(`[${docId}] doc op:`, op, "source", _source, doc.data);
        if (_source === 'user') return;
        changeSource = 'server'
        docData.set(structuredClone(doc.data));
        changeSource = 'user'
      });
    });

    const handleDiff = (prev_next: [T | undefined, T | undefined]) => {
      const [prev, next] = prev_next
      if (changeSource === 'user') {
        let diff = getDiff(prev, next)
        let ops = diffToOps(diff)
        console.log('user diff', JSON.stringify(diff))
        console.log('user ops', JSON.stringify(ops))
        doc.submitOp(ops, {source: 'user'})
      } else if (changeSource === 'server') {
        console.log('server diff', JSON.stringify(getDiff(prev, next)))
      }
    }

    docData.pipe(pairwise()).subscribe(handleDiff)
    // docData.pipe(debounce(() => timer(3000))).pipe(pairwise()).subscribe(handleDiff)

    return {
      data: docData,
      submitOp: (ops) => doc.submitOp(ops),
    };
  }

  public async init(): Promise<void> {
    // const tryCreateSessionEndpointUrl = `${getServerUrl()}/createSessionIfNeeded/${this._sessionId}`;
    // const json = await window.fetch(tryCreateSessionEndpointUrl);
    // const res = await json.json();
    // if (!res.success) {
    //   alert("Server Error!");
    // }
  }

  dispose() {
    this.docsSubscribed.forEach((d) => d.unsubscribe())
  }
}

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { Word } from "../../things.dto";
import { TH1 } from '../MiscComponents';
import { useSwipeable } from 'react-swipeable';
import { CardSideProps } from './CardSide';

export const Side0: React.FC<CardSideProps> = ({ word, cardHandlers, setCardTestMode, cardTestMode }) => {
  const { flipCard } = cardHandlers;

  const tapHandlerWord = useSwipeable({
    onTap: cardHandlers.flipCard,
    onSwipedLeft: cardHandlers.nextCard,
    onSwipedRight: cardHandlers.prevCard
  });

  return (
    <Box 
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}
    >
      <Box flexDirection="column" style={{display: "flex", width: '100%', gap: 10, alignItems: 'center', marginTop: 30}}
           className={"translation-text"}>
        <Box flexDirection="row"
             {...tapHandlerWord}
             style={{
               display: "flex",
               gap: 10, alignItems: 'center', marginBottom: 30,
               position: 'relative',
               flexWrap: 'wrap',
               justifyContent: 'center',
             }}>
          <TH1>
            {word.en}
          </TH1>
          <TH1 color={blueGrey[100]}>
            <TH1 color={blueGrey[50]}>[</TH1>
            <TH1 color={blueGrey[200]}>
              <TH1>{word.ua_transcription.trim()}</TH1>
            </TH1>
            <TH1 color={blueGrey[50]}>]</TH1>
          </TH1>
        </Box>
        {!cardTestMode && (
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'space-between', 
            width: '100%',
            height: '100%',
            flex: 1
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%'
            }}>
              <Button
                variant={'outlined'}
                onClick={() => setCardTestMode && setCardTestMode(true)}
              >
                Я це знаю!
              </Button>
              <Box sx={{ flex: 1 }} />
              <Button
                variant={'contained'}
                onClick={flipCard}
              >
                Що це?
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

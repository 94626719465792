import { Op } from "sharedb/lib/client";
import { rdiffResult } from 'recursive-diff';
import json0 from 'ot-json0';

export function diffToOps(diff: rdiffResult[]): Op[] {
  let ops: Op[] = diff.map(changeToOp);

  let ops1: Op[] = []
  for (const op of ops) {
    if (ops1.length === 0) {
      ops1.push(op)
    } else {
      // ShareDB requires operations to be applied sequentially, while diff interprets
      // all changes as happening simultaneously. This can cause issues, especially
      // with multiple operations on an array. For example, a diff like:
      // [
      //   {op: 'delete', path: ['array', 1]},
      //   {op: 'delete', path: ['array', 2]},
      //   {op: 'add', path: ['array', 0], val: 'new'},
      //   {op: 'update', path: ['array', 3], val: 'updated'}
      // ]
      // would be problematic because each operation affects the indices of subsequent operations.
      // ShareDB's desired ops for this diff would be:
      // [
      //   {p: ['array', 1], ld: true},
      //   {p: ['array', 1], ld: true}, // Note: index 1, not 2
      //   {p: ['array', 0], li: 'new'},
      //   {p: ['array', 1], li: 'updated', ld: true} // Note: index 1, not 3
      // ]
      // We need to transform each operation against all previous operations to account for these shifts.

      const [newOp] = json0.type.transform([op], ops1, 'left')
      ops1 = json0.type.compose(ops1, [newOp])
    }
  }

  return ops1
}

export function changeToOp(change: rdiffResult): Op {
  if (change.op === 'add') {
    let idx = change.path[change.path.length - 1]
    if (typeof idx === 'string') {
      return {p: change.path, oi: change.val}
    } else if (typeof idx === 'number') {
      return {p: change.path, li: change.val}
    }
  } else if (change.op === 'update') {
    let idx = change.path[change.path.length - 1]
    if (typeof idx === 'string') {
      return {p: change.path, oi: change.val}
    } else if (typeof idx === 'number') {
      return {p: change.path, li: change.val, ld: true}
    }
  } else if (change.op === 'delete') {
    let idx = change.path[change.path.length - 1]
    if (typeof idx === 'string') {
      return {p: change.path, od: change.val}
    } else if (typeof idx === 'number') {
      return {p: change.path, ld: true}
    }
  }
  throw Error(`unknown operation in diff: ${change}`)
}

import React, {useLayoutEffect, useRef} from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Fab,
  Grid,
  Typography,
  Input,
  TextField,
  TypographyOwnProps
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import useScreenSize from './useScreenSize';
import {blueGrey} from '@mui/material/colors';
import {Picture, Word} from "./things.dto";
import {Atom, F, Lens} from '@grammarly/focal';
import {map} from "rxjs/operators";
import { Button } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';

const SimpleInput: React.FC<{
  value: string,
  variant: TypographyOwnProps['variant'],
  component?: React.ElementType;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}> = (props) => {
  return (
    <Typography variant={props.variant} component={props.component || 'span'} sx={{position: "relative"}}>
      <Input
        value={props.value}
        sx={{
          fontSize: "inherit",
          color: "inherit",
          position: "absolute",
          width: "100%",
          zIndex: 10
        }}
        inputProps={{style: {letterSpacing: "normal"}}}
        onChange={props.onChange}
      />
      <span style={{
        letterSpacing: "normal",
        zIndex: 0,
        opacity: 0,
        lineHeight: '1.4375em'
      }}>{props.value}</span>
    </Typography>
  );
};

const WordEditor: React.FC<{ wordAtom: Atom<Word> }> = ({wordAtom}) => {
  const {width, height} = useScreenSize();
  const aspectRatio = width / height;
  const scrolledRef = useRef(null);

  useLayoutEffect(() => {
    scrolledRef.current && (scrolledRef.current as any).scrollTo({top: 0});
  }, [wordAtom]);

  return <F.Fragment>{
    wordAtom.pipe(map(word => {
      const columns = Math.max(2, word.pictures.length);
      return <Box sx={{
        maxWidth: '100vw',
        maxHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Card variant="outlined" sx={{
          height: 'calc(100%-200px)',
          width: '90%',
          display: 'flex', flexDirection: 'column',
        }}>
          <CardContent sx={{flexGrow: 1, overflowY: 'auto', alignItems: 'center', justifySelf: 'center'}}
                       ref={scrolledRef}>
            <Box flexDirection="column" style={{display: "flex", gap: 10, alignItems: 'center', marginTop: 30}}
                 className={"translation-text"}>
              <Box flexDirection="row"
                   style={{
                     display: "flex", gap: 10, alignItems: 'center', marginBottom: 30,
                     position: 'relative'
                   }}>
                <SimpleInput value={word.en}
                             variant={"h4"}
                             component={"span"}
                             onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                               wordAtom.modify(mutate((w) => {
                                 w.en = event.target.value;
                                 w.dependencies.en.v += 1;
                               }));
                             }}/>
                <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                  <Typography variant="h4" component="span" color={blueGrey[100]} key={"bla"}>
                    [ <SimpleInput variant={"h4"} value={word.ua_transcription}
                                   onChange={(e) => {
                                     wordAtom.modify(mutate((w) => {
                                       w.ua_transcription = e.target.value;
                                       w.dependencies.ua_transcription.v += 1;
                                     }));
                                   }}/> ]
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        wordAtom.modify(mutate((w) => {
                          w.dependencies.ua_transcription.gate += 1;
                        }));
                      }}
                      sx={{
                        minWidth: '36px',
                        height: '36px',
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    >
                      {word.dependencies.ua_transcription.gate}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => {
                        wordAtom.modify(mutate((w) => {
                          w.dependencies.ua_transcription.gate = 0;
                        }));
                      }}
                      sx={{
                        minWidth: '36px',
                        height: '36px',
                        p: 0,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderTop: 'none',
                      }}
                    >
                      <RestartAltIcon fontSize="small" />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              flexDirection="column" style={{display: "flex", gap: 10, alignItems: 'center', marginTop: 30}}
              className={"translation-text"}>
              {word.memo && (
                <Box sx={{ display: 'flex', alignItems: 'flex-start'}}>
                  <Typography variant="h3" component="span" color={blueGrey[50]} sx={{ flexGrow: 1 }}>
                    ну типу [<SimpleInput
                      variant={"h3"}
                      value={word.memo || "editme!"}
                      onChange={(e) => {
                        wordAtom.modify(mutate((w) => {
                          w.memo = e.target.value;
                          w.dependencies.memo.v += 1;
                        }));
                      }}
                    />]
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        wordAtom.modify(mutate((w) => {
                          w.dependencies.memo.gate += 1;
                        }));
                      }}
                      sx={{
                        minWidth: '36px',
                        height: '36px',
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    >
                      {word.dependencies.memo.gate}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => {
                        wordAtom.modify(mutate((w) => {
                          w.dependencies.memo.gate = 0;
                        }));
                      }}
                      sx={{
                        minWidth: '36px',
                        height: '36px',
                        p: 0,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderTop: 'none',
                      }}
                    >
                      <RestartAltIcon fontSize="small" />
                    </Button>
                  </Box>
                </Box>
              )}
              {word.ua && word.ua.length > 0 && (
                <Box
                  flexDirection="row" style={{
                  display: "flex", gap: 20, flexWrap: 'wrap', justifyContent: "center"
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, flexGrow: 1 }}>
                      {word.ua.split('|').map((x, i) => (
                        <Typography key={i}
                                    className="translation" variant={"h3"}
                                    color={blueGrey[200]}>
                          <SimpleInput variant={"h3"} value={x || "editme!"}
                                       onChange={(e) => {
                                         wordAtom.modify(mutate((w) => {
                                           let ww = w.ua.split('|');
                                           if (!e.target.value) {
                                             ww.splice(i, 1);
                                           } else {
                                             let aa = e.target.value.split(" ").filter((x) => x);
                                             ww = ww.slice(0, i).concat(aa, ww.slice(i + 1));
                                           }
                                           w.ua = ww.join('|');
                                           w.dependencies.ua.v += 1;
                                         }));
                                       }}/>
                        </Typography>
                      ))}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          wordAtom.modify(mutate((w) => {
                            w.dependencies.ua.gate += 1;
                          }));
                        }}
                        sx={{
                          minWidth: '36px',
                          height: '36px',
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                      >
                        {word.dependencies.ua.gate}
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                          wordAtom.modify(mutate((w) => {
                            w.dependencies.ua.gate = 0;
                          }));
                        }}
                        sx={{
                          minWidth: '36px',
                          height: '36px',
                          p: 0,
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          borderTop: 'none',
                        }}
                      >
                        <RestartAltIcon fontSize="small" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
              {word.memo_details && <Box sx={{width: '100%', marginTop: 4}}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                    <TextField
                      value={word.memo_details || ''}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        wordAtom.modify(mutate((w) => {
                          w.memo_details = event.target.value;
                          w.dependencies.memo_details.v += 1;
                        }));
                      }}
                      variant="outlined"
                      multiline
                      fullWidth
                      sx={{ flexGrow: 1 }}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          wordAtom.modify(mutate((w) => {
                            w.dependencies.memo_details.gate += 1;
                          }));
                        }}
                        sx={{
                          minWidth: '36px',
                          height: '36px',
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                      >
                        {word.dependencies.memo_details.gate}
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                          wordAtom.modify(mutate((w) => {
                            w.dependencies.memo_details.gate = 0;
                          }));
                        }}
                        sx={{
                          minWidth: '36px',
                          height: '36px',
                          p: 0,
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          borderTop: 'none',
                        }}
                      >
                        <RestartAltIcon fontSize="small" />
                      </Button>
                    </Box>
                  </Box>
              </Box>}
              {word.pictures && word.pictures.length > 0 && (
                <Grid container spacing={2}
                      sx={{height: 'calc(100% - 100px)', alignItems: 'center', justifyContent: 'center'}}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mb: 2, mt: 2.5 }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        wordAtom.modify(mutate((w) => {
                          w.dependencies.pictures.gate += 1;
                        }));
                      }}
                      sx={{ mr: 1 }}
                    >
                      {word.dependencies.pictures.gate}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => {
                        wordAtom.modify(mutate((w) => {
                          w.dependencies.pictures.gate = 0;
                        }));
                      }}
                    >
                      <RestartAltIcon fontSize="small" />
                    </Button>
                    <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                      {word.dependencies.pictures.complete ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <CircularProgress size={24} />
                      )}
                    </Box>
                  </Box>
                  {word.pictures.map((pic: Picture, index: number) => (
                    <Grid item xs={12 / (aspectRatio > 1 ? columns : 1)}
                          key={index + `${pic.path}`}
                          sx={{
                            display: 'flex', justifyContent: 'center', flexDirection: 'column',
                            ...pic.removed ? {opacity: 0.1} : {}
                          }}>
                      <CardMedia
                        component="img"
                        image={`${pic.path}`}
                        alt={word.memo ? `ну типу ${word.memo}, поняв?` : `Image ${index}`}
                      />
                      <Box sx={{position: 'relative', height: 50, marginTop: -7}}>
                        <Box sx={{position: 'absolute', left: 0, margin: 0.5}}>
                          <F.Fragment>
                            <Fab size="small" color={pic.fav ? "secondary" : "primary"}
                                 style={{margin: 2}}
                                 onClick={() => wordAtom.modify(mutate((w) => {
                                   w.pictures[index].fav = !w.pictures[index].fav;
                                 }))}>
                              {pic.fav ? <FavoriteIcon/> : <FavoriteBorderIcon/>}
                            </Fab>
                          </F.Fragment>
                        </Box>
                        <Box sx={{position: 'absolute', right: 0, margin: 0.5}}>
                          <Fab size="small" color="primary" aria-label="add" style={{margin: 2}}
                               onClick={() => wordAtom.modify(mutate((w) => {
                                 w.pictures[index].removed = !w.pictures[index].removed;
                               }))}>
                            {pic.removed ? <AddIcon/> : <RemoveIcon/>}
                          </Fab>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    }))
  }

  </F.Fragment>
};

export default WordEditor;

function deepClone<T>(o: T): T {
  return JSON.parse(JSON.stringify(o)) as T;
}

function mutate<T>(fn: (v: NonNullable<T>) => void) {
  return (v: T) => {
    let v1 = deepClone(v!);
    fn(v1);
    return v1;
  }
}
import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import * as ShareDB from 'sharedb/lib/client';
import ReconnectingWebSocket from "reconnecting-websocket";
import {Connection} from 'sharedb/lib/client';
import {SessionAPI, SubmitOp} from './SessionAPI';
import {Session} from "../things.dto";

let sessionsCount = 0;

export const useSession = (sessionId: string): SessionAPI | undefined => {
  const [sessionAPI, setSessionAPI] = useState<SessionAPI | undefined>(undefined)

  let sessionInitId = sessionsCount++;

  useEffect(() => {
    console.log(`useSession init ${sessionId} ${sessionInitId}`)

    const wsUrl = `ws://${window.location.hostname}:8080`;
    const socket = new ReconnectingWebSocket(wsUrl);
    const connection = new Connection(socket as any);

    let sessionAPI = new SessionAPI(connection, sessionId)

    let wasInitCancelled = false;
    (async () => {
      try {
        await sessionAPI.init();
        if (!wasInitCancelled) {
          setSessionAPI(sessionAPI)
        }
      } catch (e) {
        console.error("Failed to initialize session docs.", e);
      }
    })();

    // Clean up the subscription on unmount
    return () => {
      console.log(`useSession deinit ${sessionId} ${sessionInitId}`)
      sessionAPI?.dispose();
      setSessionAPI(undefined);
      // connection.close();
      wasInitCancelled = true;
    };

  }, [sessionId]);

  return sessionAPI
}

const SessionContext = createContext<SessionAPI | undefined>(undefined);

export const SessionProvider: React.FC<{
  sessionId: string;
  children: React.ReactNode;
}> = ({sessionId, children}) => {
  const [isLoading, setIsLoading] = useState(true);
  const sessionAPI = useSession(sessionId);

  useEffect(() => {
    if (sessionAPI) {
      setIsLoading(false);
    }
  }, [sessionAPI]);

  if (isLoading) {
    return <div>Initializing session...</div>;
  }

  return (
    <SessionContext.Provider value={sessionAPI}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext = (): SessionAPI | undefined => React.useContext(SessionContext);

interface SessionGuardProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

export const SessionGuard: React.FC<SessionGuardProps> = ({ children, fallback }) => {
  const sessionAPI = useSessionContext();

  if (!sessionAPI) {
    return fallback ? <>{fallback}</> : null;
  }

  return <>{children}</>;
};


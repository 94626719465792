import { Connection, Doc, Op } from "sharedb/lib/client";
import { Atom } from "@grammarly/focal";
import { getDiff, rdiffResult } from 'recursive-diff';
import { pairwise } from 'rxjs/operators';
import { diffToOps } from './diff-utils';

export type SubmitOp = (ops: Op[]) => void;

interface DocSubscription<T> {
  data: Atom<T | undefined>;
  submitOp: SubmitOp;
}

export class UserSessionAPI {
  private readonly subscriptionsCache = new Map<string, DocSubscription<unknown>>();
  private readonly docsSubscribed: Doc[] = [];

  constructor(
    private _connection: Connection,
    private userId: string,
    private serverUrl: string
  ) {}

  public getMapDoc<T>(): DocSubscription<T> {
    return this.getDocSubscription<T>('map');
  }

  public getLocationDoc<T>(locationId: string): DocSubscription<T> {
    return this.getDocSubscription<T>(`location/${locationId}`);
  }

  public getProfileDoc<T>(): DocSubscription<T> {
    return this.getDocSubscription<T>('profile');
  }

  private getDocSubscription<T>(docId: string): DocSubscription<T> {
    const cacheKey = `${this.userId}/${docId}`;
    
    if (this.subscriptionsCache.has(cacheKey)) {
      return this.subscriptionsCache.get(cacheKey) as DocSubscription<T>;
    }

    const newSubscription = this._createDocSubscription<T>(docId);
    this.subscriptionsCache.set(cacheKey, newSubscription as DocSubscription<unknown>);

    return newSubscription;
  }

  private _createDocSubscription<T>(docId: string): DocSubscription<T> {
    console.log(`Creating subscription for ${this.userId}/${docId}`);
    let doc = this._connection.get(this.userId, docId);
    let docData = Atom.create<T | undefined>(undefined);
    let changeSource: 'server' | 'user' = 'user';

    doc.subscribe((err: unknown) => {
      if (err) return console.error("ShareDB error:", err);
      console.log(`Got initial value for ${this.userId}/${docId}`, doc.data);
      this.docsSubscribed.push(doc);
      changeSource = 'server';
      docData.set(structuredClone(doc.data));
      changeSource = 'user';

      doc.on("op", (op: unknown, _source: unknown) => {
        console.log(`[${this.userId}/${docId}] doc op:`, op, "source", _source, doc.data);
        if (_source === 'user') return;
        changeSource = 'server';
        docData.set(structuredClone(doc.data));
        changeSource = 'user';
      });
    });

    const handleDiff = (prev_next: [T | undefined, T | undefined]) => {
      const [prev, next] = prev_next;
      if (changeSource === 'user') {
        let diff = getDiff(prev, next);
        let ops = diffToOps(diff);
        console.log('user diff', JSON.stringify(diff));
        console.log('user ops', JSON.stringify(ops));
        doc.submitOp(ops, {source: 'user'});
      } else if (changeSource === 'server') {
        console.log('server diff', JSON.stringify(getDiff(prev, next)));
      }
    };

    docData.pipe(pairwise()).subscribe(handleDiff);

    return {
      data: docData,
      submitOp: (ops) => doc.submitOp(ops),
    };
  }

  private async createUserMap(): Promise<void> {
    try {
      const createUserMapEndpointUrl = `${this.serverUrl}/create-user-map`;
      const createUserMapResponse = await fetch(createUserMapEndpointUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: this.userId }),
      });
      const createUserMapResult = await createUserMapResponse.json();
      if (!createUserMapResult.success) {
        throw new Error("Failed to create user map");
      }
    } catch (error) {
      console.error("Error during session initialization:", error);
    }
  }

  public async init(): Promise<void> {
    await this.createUserMap();
  }

  public dispose() {
    this.docsSubscribed.forEach((d) => d.unsubscribe());
  }
}

import React, { createContext, useContext, useEffect, useState } from 'react';
import ReconnectingWebSocket from "reconnecting-websocket";
import { Connection } from 'sharedb/lib/client';
import { UserSessionAPI } from './UserSessionAPI';

// Create a context for the UserSessionAPI
const UserSessionContext = createContext<UserSessionAPI | undefined>(undefined);

// Counter for debugging purposes
let userSessionsCount = 0;

// Hook to create and manage UserSessionAPI
export const useUserSession = (userId: string): UserSessionAPI | undefined => {
  const [userSessionAPI, setUserSessionAPI] = useState<UserSessionAPI | undefined>(undefined);
  const userSessionInitId = userSessionsCount++;

  useEffect(() => {
    console.log(`useUserSession init ${userId} ${userSessionInitId}`);

    const wsUrl = getWsUrl();
    const socket = new ReconnectingWebSocket(wsUrl);
    const connection = new Connection(socket as any);

    const userSessionAPI = new UserSessionAPI(connection, userId, getServerUrl());

    let wasInitCancelled = false;
    (async () => {
      try {
        await userSessionAPI.init();
        if (!wasInitCancelled) {
          setUserSessionAPI(userSessionAPI);
        }
      } catch (e) {
        console.error("Failed to initialize user session docs.", e);
      }
    })();

    // Clean up the subscription on unmount
    return () => {
      console.log(`useUserSession deinit ${userId} ${userSessionInitId}`);
      userSessionAPI.dispose();
      setUserSessionAPI(undefined);
      wasInitCancelled = true;
    };
  }, [userId]);

  return userSessionAPI;
};

// Provider component
export const UserSessionProvider: React.FC<{
  userId: string;
  children: React.ReactNode;
}> = ({ userId, children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const userSessionAPI = useUserSession(userId);

  useEffect(() => {
    if (userSessionAPI) {
      setIsLoading(false);
    }
  }, [userSessionAPI]);

  if (isLoading) {
    return <div>Initializing user session...</div>;
  }

  return (
    <UserSessionContext.Provider value={userSessionAPI}>
      {children}
    </UserSessionContext.Provider>
  );
};

// Hook to use the UserSessionAPI in components
export const useUserSessionContext = (): UserSessionAPI | undefined => useContext(UserSessionContext);

// UserSessionGuard component
interface UserSessionGuardProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

export const UserSessionGuard: React.FC<UserSessionGuardProps> = ({ children, fallback }) => {
  const userSessionAPI = useUserSessionContext();

  if (!userSessionAPI) {
    return fallback ? <>{fallback}</> : null;
  }

  return <>{children}</>;
};

function getWsUrl() {
  const serverUrl = getServerUrl();
  return serverUrl.startsWith('https') ? serverUrl.replace('https', 'wss') : serverUrl.replace('http', 'ws');
}

function getServerUrl() {
  const { protocol, hostname, port } = window.location;
  const serverPort = (port === '3000' ? '8080' : port); // Use 8080 if running on dev server (port 3000)
  return `${protocol}//${hostname}:${serverPort}`;
}

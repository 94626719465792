import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, AppBar, Toolbar } from '@mui/material';
import backgroundPattern from './backgroundPattern.svg';
import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
  name?: string;
  email?: string;
}

const UserLoginPage: React.FC = () => {
  const [userId, setUserId] = useState('unknown-user-test');
  const [userName, setUserName] = useState('unknown-user-test');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch('/api/user-info');
        if (response.ok) {
          const data = await response.json();
          console.log('data', data);
          
          const userName = data.userName || 'Unknown User';
          let userId_ = userName.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
          setUserName(userName);
          setUserId(userId_);
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (userId.trim()) {
      navigate(`/${userId}/map`);
    }
  };

  const handleLogout = () => {
    window.location.href = '/logout';
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: '#0057b7' }}>
        <Toolbar sx={{ justifyContent: 'flex-end' }}>
          {userName && (
            <Button color="inherit" onClick={handleLogout} variant="outlined">
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 64px)', // Subtract AppBar height
        padding: 2,
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${backgroundPattern})`,
          backgroundColor: '#0057b7',
          opacity: 0.8,
          zIndex: -1,
        },
      }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            padding: 4,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderRadius: 2,
            maxWidth: '400px',
            width: '100%',
            opacity: 1,
          }}
        >
          <Box
            component="img"
            src='/ghostIcon.png'
            sx={{
              width: '100px',
              margin: '0 auto',
              display: 'block',
            }}
          />
          <Typography variant="h4" component="h1" sx={{ color: '#0057b7', textAlign: 'center' }}>
            {userName ? `Привіт, ${userName}!` : 'Привіт!'}
          </Typography>
          <Typography variant="body1" sx={{ color: '#0057b7', textAlign: 'center' }}>
            Введи як тебе звати, щоб почати.
          </Typography>
          <TextField
            label="Логін: використовуй маленькі a-z, 0-9, -"
            variant="outlined"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            fullWidth
            required
          />
          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: '#0057b7',
              '&:hover': {
                backgroundColor: '#003c8f',
              },
            }}
          >
            Погнали!
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UserLoginPage;

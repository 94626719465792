import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Atom } from '@grammarly/focal';
import { Word } from "../things.dto";
import { CardHandlers } from './card-sides/CardSide';

export const WordCardSideTest: React.FC<{ 
  wordAtom: Atom<Word>, 
  setTestMode: (mode: boolean) => void,
  testOptions: string[],
  children?: React.ReactNode,
  cardHandlers: CardHandlers
}> = ({wordAtom, setTestMode, testOptions, children, cardHandlers}) => {
  const word = wordAtom.get();

  const [flashRed, setFlashRed] = useState(false);
  const [flashGreen, setFlashGreen] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Start the timer when the component mounts
    const newTimer = setTimeout(() => {
      onFalseOption('Timeout');
    }, 5000);

    setTimer(newTimer);

    // Clear the timer when the component unmounts
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  const handleButtonClick = (e: React.MouseEvent, option: string) => {
    console.log('preventing default');
    e.stopPropagation(); // Prevent event from bubbling up
    e.preventDefault();
    
    if (timer) clearTimeout(timer);

    const correctOptions = word.ua.split('|').map(opt => opt.trim());
    if (correctOptions.includes(option)) {
      onTrueOption(option);
    } else {
      onFalseOption(option);
    }
  }

  const onTrueOption = (v: string) => {
    console.log('true option selected', v);
    setFlashGreen(true);
    setTimeout(() => {
      wordAtom.modify((w) => ({...w, i_know_it: true}));
      setFlashGreen(false);
      setTestMode(false);
      // cardHandlers.nextCard(); // Switch to next word
    }, 1000);
  }

  const onFalseOption = (v: string) => {
    console.log('false option selected', v);
    setFlashRed(true);
    setTimeout(() => {
      setFlashRed(false);
      setTestMode(false);
    }, 1000);
  }

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
      {flashRed && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 0, 0, 0.5)',
            zIndex: 1000,
            animation: 'flashFadeRed 1s ease-out',
            '@keyframes flashFadeRed': {
              '0%': { opacity: 1 },
              '100%': { opacity: 0 }
            }
          }}
        />
      )}
      {flashGreen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 255, 0, 0.5)',
            zIndex: 1000,
            animation: 'flashFadeGreen 1s ease-out',
            '@keyframes flashFadeGreen': {
              '0%, 20%, 40%, 60%, 80%': { opacity: 1 },
              '10%, 30%, 50%, 70%, 90%': { opacity: 0 },
              '100%': { opacity: 0 }
            }
          }}
        />
      )}
      {children}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>
        {testOptions.map((option, index) => (
          <Button
            key={index}
            variant="contained"
            size="large"
            onClick={(e) => handleButtonClick(e, option)}
          >
            {option}
          </Button>
        ))}
      </Box>
    </Box>
  );
}

function deepClone<T>(o: T): T {
  return JSON.parse(JSON.stringify(o)) as T;
}

function mutate<T>(fn: (v: NonNullable<T>) => void) {
  return (v: T) => {
    let v1 = deepClone(v!);
    fn(v1);
    return v1;
  }
}



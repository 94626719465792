import React, {useLayoutEffect, useRef, useState} from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Fab,
  Grid,
  Typography,
  TextField,
  TypographyOwnProps,
  Input
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FlipCameraAndroidOutlinedIcon from '@mui/icons-material/FlipCameraAndroidOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import useScreenSize from './useScreenSize';
import {blueGrey} from '@mui/material/colors';
import {Picture, Session, Word} from "./things.dto";
import {Atom, F, Lens} from '@grammarly/focal';
import {map} from "rxjs/operators";

const SimpleInput: React.FC<{
  value: string,
  variant: TypographyOwnProps['variant'],
  component?: React.ElementType;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}> = (props) => {
  return (
    <Typography variant={props.variant} component={props.component || 'span'} sx={{position: "relative"}}>
      <Input
        value={props.value}
        sx={{
          fontSize: "inherit",
          color: "inherit",
          position: "absolute",
          width: "100%",
          zIndex: 10
        }}
        inputProps={{style: {letterSpacing: "normal"}}}
        onChange={props.onChange}
      />
      <span style={{
        letterSpacing: "normal",
        zIndex: 0,
        opacity: 0,
        lineHeight: '1.4375em',
        paddingLeft: 10,
        paddingRight: 10
      }}>{props.value}</span>
    </Typography>
  );
};

const WordStudy: React.FC<{ wordAtom: Atom<Word>, sessionAtom: Atom<Session> }> = ({wordAtom, sessionAtom}) => {
  const {width, height} = useScreenSize();
  const aspectRatio = width / height;
  const scrolledRef = useRef(null);

  const [cardSide, setCardSide] = useState<number>(0);

  const totalSides = 5;

  function flipCard() {
    setCardSide((n) => (n + 1) % totalSides)
  }

  function nextWord() {
    const session = sessionAtom.get()

    sessionAtom.modify((session0) => {

      const historyIdx = session0.historyIdx
      const history = session0.history

      let nextHistoryIdx: number;
      let nextWordIdx: number;
      let nextHistory: number[];

      if (historyIdx < history.length - 1) {
        nextHistoryIdx = historyIdx + 1;
        nextWordIdx = history[nextHistoryIdx]
        nextHistory = session.history
      } else {
        nextHistoryIdx = historyIdx + 1;
        // nextWordIdx = Math.floor(Math.random() * session.words.length)
        nextWordIdx = Math.floor((session.selectedWordIdx+1) % session.words.length)
        nextHistory = history.concat([nextWordIdx])
      }

      return {
        ...session0,
        history: nextHistory,
        historyIdx: nextHistoryIdx,
        selectedWordIdx: nextWordIdx
      }
    })
    setCardSide(0)
  }

  function prevWord() {
    const session = sessionAtom.get()
    let prevHistoryIdx = Math.max(0, session.historyIdx - 1)
    const prevWordIdx = session.history[prevHistoryIdx]

    sessionAtom.modify((session0) => {
      return {
        ...session0,
        historyIdx: prevHistoryIdx,
        selectedWordIdx: prevWordIdx
      }
    })
    setCardSide(0)
  }

  function exitStudy() {
    sessionAtom.lens('mode').modify((m) => 'edit')
  }

  useLayoutEffect(() => {
    scrolledRef.current && (scrolledRef.current as any).scrollTo({top: 0});
  }, [wordAtom]);

  return <F.Fragment>{
    wordAtom.pipe(map(word => {
      const columns = Math.max(2, word.pictures.length);
      return <Box sx={{
        maxWidth: '100vw',
        maxHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Card variant="outlined" sx={{
          height: 'calc(100%-200px)',
          width: '90%',
          display: 'flex', flexDirection: 'column',
        }}>
          <CardContent
            sx={{flexGrow: 1, overflowY: 'auto', alignItems: 'center', justifySelf: 'center', position: 'relative'}}
            ref={scrolledRef}>
            <Fab size="small" color="primary" aria-label="flip"
                 style={{float: 'left'}}
                 onClick={() => prevWord()}>
              <KeyboardArrowLeftIcon/>
            </Fab>
            <Fab size="small" color="primary" aria-label="flip"
                 style={{marginLeft: 'calc(50% - 80px)'}}
                 onClick={() => flipCard()}>
              <FlipCameraAndroidOutlinedIcon/>
            </Fab>
            <Fab size="small" color="primary" aria-label="flip"
                 style={{float: 'right'}}
                 onClick={() => nextWord()}>
              <KeyboardArrowRightIcon/>
            </Fab>
            <Fab size="small" color="primary" aria-label="flip"
                 style={{float: 'right', marginRight: 30}}
                 onClick={() => exitStudy()}>
              <FullscreenExitIcon/>
            </Fab>


            {(0 === cardSide || cardSide === 1 || cardSide === 2 || cardSide === 3) &&
              // this is a word with transcription
              // screens 0, 1, 3

                <Box flexDirection="column" style={{display: "flex", gap: 10, alignItems: 'center', marginTop: 30}}
                     className={"translation-text"}>
                    <Box flexDirection="row"
                         style={{
                           display: "flex",
                           gap: 10, alignItems: 'center', marginBottom: 30,
                           position: 'relative',
                           flexWrap: 'wrap',
                           justifyContent: 'center'
                         }}>
                        <SimpleInput value={word.en}
                                     variant={"h1"}
                                     component={"span"}
                                     onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                       wordAtom.modify((w) => {
                                         w = deepClone(w);
                                         w.en = event.target.value;
                                         return w;
                                       });
                                     }}/>
                        <Typography variant="h1" component="span" color={blueGrey[100]} key={"bla"}>
                            [ <SimpleInput variant={"h1"} value={word.ua_transcription}
                                           onChange={(e) => {
                                             wordAtom.modify(mutate((w) => (
                                               w.ua_transcription = e.target.value)));
                                           }}/> ]
                        </Typography>
                    </Box>
                </Box>}


            <Box
              flexDirection="column" style={{display: "flex", gap: 10, alignItems: 'center', marginTop: 0}}
              className={"translation-text"}>


              {((1 <= cardSide && cardSide <= 3) && word.memo &&
                // this is a hint
                // screens 1, 3

                  <Typography variant="h3" component="span" color={blueGrey[50]}>
                      [<SimpleInput variant={"h3"} value={word.ua_transcription || "editme!"}
                                    onChange={(e) => {
                                      wordAtom.modify(mutate((w) => (
                                        w.ua_transcription = e.target.value)));
                                    }}/>]
                      це типу як 
                      [<SimpleInput variant={"h3"} value={word.memo || "editme!"}
                                    onChange={(e) => {
                                      wordAtom.modify(mutate((w) => (
                                        w.memo = e.target.value)));
                                    }}/>]
                  </Typography>)}

              {(1 <= cardSide && cardSide <= 3) &&
                // this is translation
                // screen 3

                  <Box
                      flexDirection="row" style={{
                    display: "flex", gap: 20, flexWrap: 'wrap', justifyContent: "center"
                  }}>
                    {word.ua.split('|').map((x, i) => (
                      <Typography key={i}
                                  className="translation" variant={"h1"}
                                  color={blueGrey["800"]}>
                        <SimpleInput variant={"h1"} value={x || "editme!"}
                                     onChange={(e) => {
                                       wordAtom.modify(mutate((w) => {
                                         let ww = w.ua.split('|');
                                         if (!e.target.value) {
                                           ww.splice(i, 1);
                                         } else {
                                           let aa = e.target.value.split(" ").filter((x) => x);
                                           ww = ww.slice(0, i).concat(aa, ww.slice(i + 1));
                                         }
                                         w.ua = ww.join('|');
                                       }));
                                     }}/>
                      </Typography>
                    ))}
                  </Box>}




              {(2 === cardSide || 4 === cardSide) && word.memo_details &&
                // this is long hint
                // screen 2, 4, the same as pictures

                  <Box sx={{width: '100%', marginTop: 4}}>
                      <TextField
                          inputProps={{
                            style: {
                              fontSize: 45, lineHeight: 1,
                              color: blueGrey[200]
                            }
                          }}
                          value={word.memo_details || ''}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            wordAtom.modify((w) => {
                              w = deepClone(w!);
                              w.memo_details = event.target.value;
                              return w;
                            });
                          }}
                          variant="outlined"
                          multiline
                        // rows={4}
                          fullWidth
                      />
                  </Box>}

              {(2 === cardSide || 4 === cardSide) &&
                // this is pictures
                // screen 2, 4

                  <Grid container spacing={2}
                        sx={{height: 'calc(100% - 100px)', alignItems: 'center', justifyContent: 'center'}}>
                    {word.pictures.map((pic: Picture, index: number) => (
                      <Grid item xs={12 / (aspectRatio > 1 ? columns : columns)}
                            key={index + `${pic.path}`}
                            sx={{
                              display: 'flex', justifyContent: 'center', flexDirection: 'column',
                              ...pic.removed ? {opacity: 0.1} : {}
                            }}>
                        <CardMedia
                          component="img"
                          image={`${pic.path}`}
                          alt={word.memo ? `ну типу ${word.memo}, поняв?` : `Image ${index}`}
                        />
                        <Box sx={{position: 'relative', height: 50, marginTop: -7}}>
                          <Box sx={{position: 'absolute', left: 0, margin: 0.5}}>
                            <F.Fragment>
                              <Fab size="small" color={pic.fav ? "secondary" : "primary"}
                                   style={{margin: 2}}
                                   onClick={() => wordAtom.modify(mutate((w) => (
                                     w.pictures[index].fav = !w.pictures[index].fav)))}>
                                {pic.fav ? <FavoriteIcon/> : <FavoriteBorderIcon/>}
                              </Fab>
                            </F.Fragment>
                          </Box>
                          <Box sx={{position: 'absolute', right: 0, margin: 0.5}}>
                            <Fab size="small" color="primary" aria-label="add" style={{margin: 2}}
                                 onClick={() => wordAtom.modify((w) => {
                                   w = deepClone(w!);
                                   w.pictures[index].removed = !w.pictures[index].removed;
                                   return w;
                                 })}>
                              {pic.removed ? <AddIcon/> : <RemoveIcon/>}
                            </Fab>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>}
            </Box>
          </CardContent>
        </Card>
      </Box>
    }))
  }

  </F.Fragment>
};

export default WordStudy;

function deepClone<T>(o: T): T {
  return JSON.parse(JSON.stringify(o)) as T;
}

function mutate<T>(fn: (v: NonNullable<T>) => void) {
  return (v: T) => {
    let v1 = deepClone(v!);
    fn(v1);
    return v1;
  }
}
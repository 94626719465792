import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { TH1, SimpleInput } from '../MiscComponents';
import { useSwipeable } from 'react-swipeable';
import { CardSideProps } from './CardSide';

export const Side2: React.FC<CardSideProps> = ({ word, cardHandlers }) => {

  const tapHandlers = useSwipeable({
    // onTap: cardHandlers.flipCard,
    onSwipedLeft: cardHandlers.nextCard,
    onSwipedRight: cardHandlers.prevCard
  });

  const flashAnimationSX = {
    animation: 'flashHint 1s',
    '@keyframes flashHint': {
      '0%': { backgroundColor: 'lightgreen' },
      '100%': { backgroundColor: 'transparent' }
    }
  }

  const blinkAnimationSX = {
    animation: 'blink 5s linear',
    '@keyframes blink': {
      '0%, 12.5%, 25%, 37.5%, 50%, 62.5%, 75%, 87.5%': { backgroundColor: 'lightgreen' },
      '6.25%, 18.75%, 31.25%, 43.75%, 56.25%, 68.75%, 81.25%, 93.75%': { backgroundColor: 'transparent' }
    }
  };

  const [buttonVariant, setButtonVariant] = useState<'outlined' | 'contained'>('outlined');

  useEffect(() => {
    const timer = setTimeout(() => {
      setButtonVariant('contained');
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}
      // Swipe handlers should be implemented at a higher level
    >
      <Box flexDirection="column" 
          style={{
            display: "flex",
            width: '100%', 
            gap: 0,
            alignItems: 'center',
            marginTop: 30
          }}
          className={"translation-text"}>
        <Box flexDirection="row"
             {...tapHandlers}
             style={{
               display: "flex",
               gap: 10, alignItems: 'center', marginBottom: 30,
               position: 'relative',
               flexWrap: 'wrap',
               justifyContent: 'center',
             }}>
          <TH1>
            {word.en}
          </TH1>
          <TH1 color={blueGrey[100]}>
            <TH1 color={blueGrey[50]}>[</TH1>
            <TH1 color={blueGrey[200]}>
              <TH1>{word.ua_transcription.trim()}</TH1>
            </TH1>
            <TH1 color={blueGrey[50]}>]</TH1>
          </TH1>
        </Box>

        <Box
          flexDirection="row" style={{
            display: "flex", gap: 20, flexWrap: 'wrap', justifyContent: "center"
          }}
        >
          <Typography variant="h3" color={blueGrey[100]}>
            це
          </Typography>
          {word.ua.split('|').map((x, i) => (
            <Typography 
              key={i} 
              color={blueGrey[600]} 
              variant="h3"
            >
              {x}
            </Typography>
          ))}
        </Box>

        <Box flexDirection="row"
             style={{
               display: "flex",
               gap: 10, 
               alignItems: 'center',
               marginTop: 30,
               position: 'relative',
               flexWrap: 'wrap',
               justifyContent: 'center',
               textAlign: 'center'
             }}>
          {word.memo && (
            <Typography 
                variant="h4" 
                component="span" 
                color={blueGrey[200]}
                sx={flashAnimationSX}
            >
              Уяви цей дивний <SimpleInput variant={"h3"} value={word.ua_transcription} color={blueGrey[400]} sx={blinkAnimationSX} /> як <SimpleInput variant={"h3"} value={word.memo} color={blueGrey[400]} sx={blinkAnimationSX}/>!
            </Typography>
          )}

        </Box>
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'space-between', 
            width: '100%',
            height: '100%',
            flex: 1,
            mt: 3
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%'
            }}>
              <Box sx={{ flex: 1 }} />
              <Button
                variant={buttonVariant}
                onClick={cardHandlers.flipCard}
              >
                Як це яскраво уявити?
              </Button>
            </Box>
          </Box>
      </Box>
    </Box>
  );
};

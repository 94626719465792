import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Atom, Lens, F } from '@grammarly/focal';
import { blueGrey } from '@mui/material/colors';
import { Session, Word } from "./things.dto";
import { map } from "rxjs/operators";
import { combineLatest } from 'rxjs';
import WordCard from "./word-card/WordCard";
import ShuffleIcon from '@mui/icons-material/Shuffle';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useUserSessionContext, UserSessionGuard } from "./session/UserSessionContext";
import Confetti from 'react-confetti';

const WordFlow: React.FC = () => {
  const { locationId, userId } = useParams<{ locationId: string, userId: string }>();
  
  return (
    <UserSessionGuard fallback={<div>Loading session...</div>}>
      <WordFlowContent userId={userId!} locationId={locationId!} />
    </UserSessionGuard>
  );
};

const WordFlowContent: React.FC<{ userId: string; locationId: string }> = ({ userId, locationId }) => {
  const navigate = useNavigate();

  const userSessionAPI = useUserSessionContext()!;

  const [isBlinking, setIsBlinking] = useState(false);
  const [cardSide, setCardSide] = useState(0);
  const [lastKnownCount, setLastKnownCount] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);

  const { data: maybeSessionAtom, submitOp } = userSessionAPI.getLocationDoc<Session>(locationId);
  const dataReady = maybeSessionAtom.view((d) => !!d)

  const sessionAtom = (maybeSessionAtom as Atom<Session>);

  function toValidIndex(index: number) {
    const words = wordsToLearnAtom.get();
    return ((index % words.length) + words.length) % words.length
  }

  const mode_ = sessionAtom.lens('mode')
  const selectedWordIdx_ = sessionAtom.lens('selectedWordIdx')
  const wordsAtom = sessionAtom.lens('words')
  const wordsToLearnAtom = wordsAtom.view((words) => words?.filter((w) => !w.i_know_it))

  const maybeWordsToLearn = maybeSessionAtom.view((session) => session?.words?.filter(w=>!w.i_know_it))

  useEffect(() => {
    if (maybeWordsToLearn.get()?.length === 0) {
      setShowConfetti(true);
      const timer = setTimeout(() => setShowConfetti(false), 5000); // Stop confetti after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [maybeWordsToLearn]);

  const totalCount_ = wordsAtom.view((words) => words.length)
  const knownCount_ = wordsAtom.view((words) => words.filter((w) => w.i_know_it).length)

  function learnIdxToAllIdx(learnIdx: number) {
    const words = wordsAtom.get();
    const unknownWords = words.filter(word => !word.i_know_it);

    if (learnIdx >= unknownWords.length) {
      return -1;
    }

    const targetWord = unknownWords[learnIdx];
    return words.findIndex(word => word.en === targetWord.en);
  }

  function resetToFirstWord() {
    sessionAtom.modify((session) => ({
      ...session,
      selectedWordIdx: 0,
      history: [0],
      historyIdx: 0
    }));
  }

  function shuffleArray<T>(array: T[]): T[] {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  }

  function shuffleWords() {
    let shuffledWords: Word[] = []

    sessionAtom.modify((session) => {
      shuffledWords = shuffleArray(session.words);
      return {
        ...session,
        words: [],
        selectedWordIdx: 0,
        history: [0],
        historyIdx: 0
      };
    });

    sessionAtom.modify((session) => {
      return {
        ...session,
        words: shuffledWords,
        selectedWordIdx: 0,
        history: [0],
        historyIdx: 0
      };
    });

  }

  return (
    <F.Fragment>{
      dataReady.pipe(map((d) => {
        console.log('dataReady change', d)
        if (!d) return null;

        console.log('sessionAtom', sessionAtom.get())

        return <F.Fragment>{
          combineLatest([selectedWordIdx_, mode_, wordsAtom]).pipe(map(([selectedWordIdx, mode, words]) => {

            if (wordsToLearnAtom.get().length === 0) {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: '100vh',
                    width: '100vw',
                    position: 'relative',
                  }}
                >
                  {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
                  <Typography variant="h4">Молодець! 🎉🥳 <br /><br />Все вивчено! 🚀📚</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ marginTop: 4 }}
                    onClick={() => {
                      sessionAtom.modify((session) => {
                        const updatedWords = session.words.map(word => ({
                          ...word,
                          i_know_it: false
                        }));
                        return {
                          ...session,
                          words: updatedWords
                        };
                      });
                      resetToFirstWord();
                      console.log("Moving to next level");
                    }}
                  >
                    <Typography variant="h5">Наступний рівень</Typography>
                  </Button>
                </Box>
              )
            }

            const wordToLearnIdx = toValidIndex(selectedWordIdx);
            const allWordsIdx = learnIdxToAllIdx(wordToLearnIdx);
            const wordAtom = wordsAtom.lens(Lens.index<Word>(allWordsIdx));

            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'calc(100vh - 100px)',
                  overflow: 'hidden',
                }}
              >
                <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                  {wordAtom && isWordAtom(wordAtom) && (
                    <WordCard
                      wordAtom={wordAtom}
                      sessionAtom={sessionAtom}
                      onCardSideChange={setCardSide}
                    />
                  )}
                </Box>
                {cardSide === 0 && (
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 2,
                    backgroundColor: 'background.paper',
                  }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => navigate(`/${userId}/map`)}
                      sx={{ mr: 2 }}
                    >
                      <ArrowBackIcon />
                    </Button>
                    <Box
                      sx={{
                        position: 'relative',
                        backgroundColor: blueGrey[100],
                        padding: '4px 12px',
                        borderRadius: 4,
                        animation: isBlinking ? 'blink 3s linear 6' : 'none',
                        '@keyframes blink': {
                          '0%, 16.667%, 33.333%, 50%, 66.667%, 83.333%, 100%': { opacity: 1 },
                          '8.333%, 25%, 41.667%, 58.333%, 75%, 91.667%': { opacity: 0.5 },
                        },
                        overflow: 'hidden',
                      }}
                    >
                      <F.Fragment>{
                        combineLatest([knownCount_, totalCount_]).pipe(map(([knownCount, totalCount]) => {
                          if (knownCount !== lastKnownCount) {
                            setLastKnownCount(knownCount);
                            setIsBlinking(true);
                            setTimeout(() => setIsBlinking(false), 3000); // Blink for 3 seconds
                          }
                          const progressPercentage = (knownCount / totalCount) * 100;
                          return (
                            <>
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  height: '100%',
                                  width: `${progressPercentage}%`,
                                  backgroundColor: 'rgba(0, 255, 0, 0.6)', // Light green transparent
                                  transition: 'width 0.5s ease-in-out',
                                }}
                              />
                              <Typography variant="h6" color="text.primary" sx={{ position: 'relative', zIndex: 1 }}>
                                [{locationId}] Знаю: {knownCount} / {totalCount}
                              </Typography>
                            </>
                          );
                        }))}</F.Fragment>
                    </Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={shuffleWords}
                    >
                      <ShuffleIcon />
                    </Button>
                  </Box>
                )}
              </Box>
            )
          }))}
        </F.Fragment>
      }))}
    </F.Fragment>
  );
};

export default WordFlow;

function isWordAtom(atom: Atom<Word | undefined>): atom is Atom<Word> {
  return atom.get() !== undefined;
}

function isSessionAtom(atom: Atom<Session | undefined>): atom is Atom<Session> {
  return atom.get() !== undefined;
}

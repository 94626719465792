import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { useSwipeable } from 'react-swipeable';
import { PictureDisplay } from '../PictureDisplay';
import { CardSideProps } from './CardSide';

export const Side3: React.FC<CardSideProps> = ({ word, wordAtom, cardHandlers }) => {
  const [buttonVariant, setButtonVariant] = useState<'outlined' | 'contained'>('outlined');
  const { flipCard } = cardHandlers;

  useEffect(() => {
    const timer = setTimeout(() => {
      setButtonVariant('contained');
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const tapHandlers = useSwipeable({
    onTap: () => {
      console.log('Tapped, flipping card', 3);
      flipCard();
    },
    onSwipedLeft: cardHandlers.nextCard,
    onSwipedRight: cardHandlers.prevCard
  });

  return (
    <Box 
      sx={{ display: 'flex', flexDirection: 'column', gap:10, width: '100%', alignItems: 'center' }}
      // Swipe handlers should be implemented at a higher level
    >
      <Box
        flexDirection="column" 
        style={{display: "flex", gap: 20, alignItems: 'center', width: '100%'}}
        className={"translation-text"}
      >
        {word.memo_details && (
          <Box {...tapHandlers} sx={{
            flexDirection: 'row',
            display: 'flex',
            textAlign: 'center',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 1,
            borderColor: blueGrey[100],
            width: '100%', 
            padding: 0,
            marginTop: 0,
            animation: 'flashLongHint 10s',
            '@keyframes flashLongHint': {
              '0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90%': { backgroundColor: 'lightgreen' },
              '5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95%, 100%': { backgroundColor: 'transparent' }
            }
          }}>
            <Typography variant="h6" color={blueGrey[400]} 
              sx={{ marginRight: 1, whiteSpace: 'pre-wrap' }} >
              Уяви таку картинку: {word.memo_details}
            </Typography>
          </Box>
        )}
        {word.pictures && word.pictures.length > 0 && (
          <PictureDisplay word={word} wordAtom={wordAtom} onTap={flipCard} />
        )}

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'space-between', 
            width: '100%',
            height: '100%',
            flex: 1,
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%'
            }}>
              <Box sx={{ flex: 1 }} />
              <Button
                variant={buttonVariant}
                onClick={cardHandlers.flipCard}
              >
                я занурився і яскраво це відчув!
              </Button>
            </Box>
          </Box>
      </Box>
    </Box>
  );
};
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { useSwipeable } from 'react-swipeable';
import { PictureDisplay } from '../PictureDisplay';
import { CardSideProps } from './CardSide';

export const Side4: React.FC<CardSideProps> = ({ word, wordAtom, cardHandlers }) => {
  const { flipCard } = cardHandlers;
  const [buttonVariant, setButtonVariant] = useState<'outlined' | 'contained'>('outlined');

  useEffect(() => {
    const timer = setTimeout(() => {
      setButtonVariant('contained');
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const tapHandlers = useSwipeable({
    onTap: () => {
      console.log('Tapped, flipping card', 4);
      flipCard();
    },
    onSwipedLeft: cardHandlers.nextCard,
    onSwipedRight: cardHandlers.prevCard
  });

  const flashAnimationSX = {
    animation: 'flashHint 5s',
    '@keyframes flashHint': {
      '0%, 20%, 40%, 60%, 80%': { backgroundColor: 'lightgreen' },
      '10%, 30%, 50%, 70%, 90%, 100%': { backgroundColor: 'transparent' }
    }
  }

  const repeatWordAnimation = {
    animation: 'flashRepeat 10s',
    '@keyframes flashRepeat': {
      '0%, 49.9%': { backgroundColor: 'transparent' },
      '50%, 55%, 60%, 65%, 70%, 75%, 80%, 85%, 90%, 95%': { backgroundColor: 'rgb(100, 255, 100)' },
      '52.5%, 57.5%, 62.5%, 67.5%, 72.5%, 77.5%, 82.5%, 87.5%, 92.5%, 97.5%, 100%': { backgroundColor: 'white' }
    }
  }

  return (
    <Box 
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap:1, alignItems: 'center' }}
    >
      <Box
        {...tapHandlers}
        sx={{
          width: '100%',
        }}
      >
        <Typography 
          variant="body1" 
          sx={{ marginRight: 1, ...flashAnimationSX }}
          color={blueGrey[400]}
        >
          Дуже добре! Занурися в картинку, відчувай все яскраво! 
          Не виходячи з картинки, голосно повтори 5 разів:
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...repeatWordAnimation }}>
          <Typography 
            variant="h3" 
            sx={{ marginRight: 1 }}
          >
            {word.en}
          </Typography>
          <Typography 
            variant="h4" 
            color={blueGrey[400]} 
          >
            [{word.ua_transcription}]
          </Typography>
        </Box>
      </Box>

      {word.pictures && word.pictures.length > 0 && (
        <PictureDisplay word={word} wordAtom={wordAtom} onTap={flipCard} />
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Typography variant="h3" color={blueGrey[400]} sx={{ display: 'inline', textAlign: 'center' }}>
          {word.ua.split('|').join(' ')}
        </Typography>
      </Box>

      <Button
        variant={buttonVariant}
        onClick={cardHandlers.nextCard}
      >
        я запам'ятав, наступне слово!
      </Button>
    </Box>
  );
};

function deepClone<T>(o: T): T {
  return JSON.parse(JSON.stringify(o)) as T;
}

function mutate<T>(fn: (v: NonNullable<T>) => void) {
  return (v: T) => {
    let v1 = deepClone(v!);
    fn(v1);
    return v1;
  }
}
import React, {useLayoutEffect, useRef, useState, useMemo} from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Fab,
  Grid,
  Typography,
  TypographyOwnProps
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FlipCameraAndroidOutlinedIcon from '@mui/icons-material/FlipCameraAndroidOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Button from '@mui/material/Button';

import useScreenSize from '../useScreenSize';
import {blueGrey} from '@mui/material/colors';
import {Picture, Session, Word} from "../things.dto";
import {Atom, F, Lens} from '@grammarly/focal';
import {map} from "rxjs/operators";
import { useSwipeable } from 'react-swipeable';
import { ReactFitty } from "react-fitty";
import { AutoTextSize } from 'auto-text-size'

export const SimpleInput: React.FC<{
  value: string,
  variant: TypographyOwnProps['variant'],
  component?: React.ElementType;
  color?: string;
  sx?: React.CSSProperties;
}> = (props) => {
  return (
    <Typography variant={props.variant} component={props.component || 'span'} 
    sx={{ position: "relative", color: props.color, ...props.sx }}>
      {props.value}
    </Typography>
  );
};

export const TH1: React.FC<{
  children: React.ReactNode,
  color?: string,
}> = (props) => {
  return (
    <Typography variant="h2" component="span" color={props.color}>
      {props.children}
    </Typography>
  );
};


import React from 'react';
import {Box, Fab, Grid, List, ListItem, ListItemButton, ListItemText, Divider} from '@mui/material';
import {Atom, Lens, F} from '@grammarly/focal';
import {Session, Word} from "./things.dto";
import {ResizableBox} from 'react-resizable';
import 'react-resizable/css/styles.css';
import {map} from "rxjs/operators";
import {combineLatest} from 'rxjs';
import WordStudy from "./WordStudy";
import WordEditor from "./WordEditor";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import {useSessionContext} from "./session/SessionContext";

const WordManager: React.FC = () => {
  const sessionAPI = useSessionContext();
  if (!sessionAPI) return null;

  const {data, submitOp} = sessionAPI.getDocSubscription<Session>('session')
  const dataReady = data.view((d) => !!d)

  const sessionDocA = (data as Atom<Session>);
  const sessionAtom = sessionDocA

  const handleWordSelect = (index0: number) => {
    const index = toValidIndex(index0)
    sessionAtom.modify((session0) => (
      {
        ...session0,
        history: [index],
        historyIdx: 0,
        selectedWordIdx: index,
      }
    ))
  };

  function toValidIndex(index: number) {
    const words = wordsAtom.get();
    return ((index % words.length) + words.length) % words.length
  }

  const mode_ = sessionAtom.lens('mode')
  const selectedWordIdx_ = sessionAtom.lens('selectedWordIdx')
  const wordsAtom = sessionAtom.lens('words')

  return (
    <F.Fragment>{
      dataReady.pipe(map((d) => {
        console.log('dataReady change', d)
        if (!d) return null;

        console.log('sessionDocA', sessionDocA.get())

        return <F.Fragment>{
          combineLatest([selectedWordIdx_, mode_]).pipe(map(([selectedWordIdx, mode]) => {
            const words = wordsAtom.get();
            const wordAtom = wordsAtom.lens(Lens.index<Word>(toValidIndex(selectedWordIdx)));
            return (
              <>
                {mode === 'edit' &&
                    <Fab size="medium" color="primary" aria-label="add"
                         style={{
                           margin: 2,
                           position: 'absolute',
                           top: 20,
                           right: 20
                         }}
                         onClick={() => mode_.modify((m) => (
                           m === 'edit' ? 'study' : 'edit'))}>
                        <FullscreenIcon/>
                    </Fab>
                }

                <Grid container
                      style={{
                        height: 'calc(100vh)',
                        overflow: 'hidden'
                      }}>
                  {mode === 'edit' && <ResizableBox
                      width={300}
                      height={Infinity}
                      axis="x"
                      minConstraints={[200, Infinity]}
                      maxConstraints={[600, Infinity]}
                      resizeHandles={['e']}
                      style={{
                        borderRight: '1px solid gray',
                        paddingRight: 10,
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                  >
                      <Box sx={{height: 'calc(100vh)', overflowY: 'scroll'}}>
                          <List>
                            {words.map((word, index) => (
                              <React.Fragment key={index}>
                                <ListItem disablePadding>
                                  <ListItemButton
                                    selected={selectedWordIdx === index}
                                    onClick={() => handleWordSelect(index)}
                                  >
                                    <ListItemText primary={word.en}/>
                                  </ListItemButton>
                                </ListItem>
                                {index < words.length - 1 && <Divider/>}
                              </React.Fragment>
                            ))}
                          </List>
                      </Box>
                  </ResizableBox>}
                  <Grid item xs>
                    {wordAtom && isWordAtom(wordAtom) && (
                      (mode_.get() === 'edit'
                        ? <WordEditor wordAtom={wordAtom}/>
                        : <WordStudy wordAtom={wordAtom} sessionAtom={sessionAtom}/>)
                    )}
                  </Grid>
                </Grid>
              </>
            )
          }))}
        </F.Fragment>
      }))}
    </F.Fragment>
  );
};

export default WordManager;

function isWordAtom(atom: Atom<Word | undefined>): atom is Atom<Word> {
  return atom.get() !== undefined;
}

function isSessionAtom(atom: Atom<Session | undefined>): atom is Atom<Session> {
  return atom.get() !== undefined;
}

import React from 'react';
import { HashRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import WordFlow from './WordFlow';
import WordManager from './WordManager';
import LocationsMap from './LocationsMap';
import UserLoginPage from './UserLoginPage';

import './App.css';
import { UserSessionProvider } from "./session/UserSessionContext";
import { SessionProvider } from "./session/SessionContext";

const AppContent: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();

  return (
    <UserSessionProvider userId={userId || 'default-user'}>
      <Routes>
        <Route path="/" element={<LocationsMap />} />
        <Route path="/map" element={<LocationsMap />} />
        <Route path="/location/:locationId" element={<WordFlow/>} />
      </Routes>
    </UserSessionProvider>
  );
};

const WordManagerContent: React.FC = () => {
  return (
    <SessionProvider sessionId='test-session'>
      <Routes>
        <Route path="/" element={<WordManager />} />
      </Routes>
    </SessionProvider>
  );
};

const App: React.FC = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<UserLoginPage />} />
          <Route path="/:userId/*" element={<AppContent />} />
          <Route path="/editor/*" element={<WordManagerContent />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
};

export default App;

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import backgroundPattern from './backgroundPattern.svg';
import { useUserSessionContext } from './session/UserSessionContext';
import { F } from '@grammarly/focal';
import { map } from 'rxjs/operators';

const LocationsMap: React.FC = () => {
  const navigate = useNavigate();
  const userSessionAPI = useUserSessionContext();
  const { userId } = useParams<{ userId: string }>();

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      padding: 10,
      m: 0,
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url(${backgroundPattern})`,
        backgroundColor: '#0057b7',
        opacity: 0.8,
        zIndex: -1,
      },
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        paddingX: 4,
        width: '100%',
        maxWidth: '300px',
        minWidth: '300px',
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        borderRadius: 2,
        padding: 3,
      }}>
 
        {userSessionAPI && (
          <F.Fragment>
            {userSessionAPI.getMapDoc<{ map: string[]; locations: any}>().data.pipe(
              map(data => {
                if (!data || !data.map || data.map.length === 0) {
                  return null;
                }

                const locations = data.locations;

                return data.map.map((locationId, index) => {
                  const location = locations[locationId];
                  return (
                    <Button
                      key={`dynamic-${locationId}`}
                      variant={
                        location.status === "completed"
                          ? "contained"
                          : "outlined" }
                      disabled={location.status === "locked"}
                      onClick={() => navigate(`/${userId}/location/${locationId}`)}
                    >
                      {location.name}
                    </Button>
                  );
                });
              })
            )}
          </F.Fragment>
        )}
      </Box>
    </Box>
  );
};

export default LocationsMap;

import React, { useLayoutEffect, useRef, useState, useMemo, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
} from '@mui/material';
import useScreenSize from '../useScreenSize';
import { Session, Word } from "../things.dto";
import { Atom, F } from '@grammarly/focal';
import { map } from "rxjs/operators";
import { blueGrey, purple } from '@mui/material/colors';

import { WordCardSide } from './WordCardSide';
import { CardHandlers } from './card-sides/CardSide';

const WordCard: React.FC<{ 
  wordAtom: Atom<Word>, 
  sessionAtom: Atom<Session>,
  onCardSideChange: (side: number) => void
}> = ({ wordAtom, sessionAtom, onCardSideChange }) => {
  const { width, height } = useScreenSize();
  const aspectRatio = width / height;
  const scrolledRef = useRef(null);

  const [cardSide, setCardSide] = useState<number>(0);
  const [cardTestMode, setCardTestMode] = useState<boolean>(false);
  const [debugMode, setDebugMode] = useState<boolean>(false);
  const [isSwipingLeft, setIsSwipingLeft] = useState<boolean>(false);
  const [isSwipingRight, setIsSwipingRight] = useState<boolean>(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [knownCount, setKnownCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isBlinking, setIsBlinking] = useState(false);

  const totalSides = 5;

  const mockWordAtom = useMemo(() => Atom.create(wordAtom.get()), []);

  function flipCard() {
    const newSide = (cardSide + 1) % totalSides;
    setCardSide(newSide);
    onCardSideChange(newSide);
  }

  function nextWord() {
    const session = sessionAtom.get()

    setCardSide(0)
    onCardSideChange(0)
    setCardTestMode(false)

    sessionAtom.modify((session0) => {
      const historyIdx = session0.historyIdx
      const history = session0.history

      let nextHistoryIdx: number;
      let nextWordIdx: number;
      let nextHistory: number[];

      if (historyIdx < history.length - 1) {
        nextHistoryIdx = historyIdx + 1;
        nextWordIdx = history[nextHistoryIdx]
        nextHistory = session.history
      } else {
        nextHistoryIdx = historyIdx + 1;
        nextWordIdx = Math.floor((session.selectedWordIdx+1) % session.words.length)
        nextHistory = history.concat([nextWordIdx])
      }

      return {
        ...session0,
        history: nextHistory,
        historyIdx: nextHistoryIdx,
        selectedWordIdx: nextWordIdx
      }
    })
  }

  function swipeLeft() {
    setIsSwipingLeft(true);
    setIsTransitioning(true);
    mockWordAtom.set(wordAtom.get());
    setTimeout(() => {
      nextWord();
      setIsSwipingLeft(false);
      setIsResetting(true);
      setTimeout(() => {
        setIsTransitioning(false);
        setIsResetting(false);
      }, 50);
    }, 300);
  }

  function prevWord() {
    const session = sessionAtom.get()
    let prevHistoryIdx = Math.max(0, session.historyIdx - 1)
    const prevWordIdx = session.history[prevHistoryIdx]
    setCardSide(0)
    onCardSideChange(0)
    setCardTestMode(false)

    sessionAtom.modify((session0) => {
      return {
        ...session0,
        historyIdx: prevHistoryIdx,
        selectedWordIdx: prevWordIdx
      }
    })
  }

  function swipeRight() {
    setIsSwipingRight(true);
    setIsTransitioning(true);
    mockWordAtom.set(wordAtom.get());
    setTimeout(() => {
      prevWord();
      setIsSwipingRight(false);
      setIsResetting(true);
      setTimeout(() => {
        setIsTransitioning(false);
        setIsResetting(false);
      }, 50);
    }, 300);
  }

  const cardHandlers: CardHandlers = {
    flipCard,
    nextCard: swipeLeft,
    prevCard: swipeRight
  };

  useLayoutEffect(() => {
    scrolledRef.current && (scrolledRef.current as any).scrollTo({top: 0});
  }, [wordAtom]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    
    const session = sessionAtom.get();
    const unknownWords = session.words.filter(word => !word.i_know_it);
    const currentWordIndex = unknownWords.findIndex(word => word.en === wordAtom.get().en);
    const newProgress = ((currentWordIndex) / (unknownWords.length-1)) * 100;
    // const newProgress = ((currentWordIndex+1) / (unknownWords.length)) * 100;
    setProgress(newProgress);

  }, [wordAtom, sessionAtom, knownCount]);

  return <F.Fragment>{
    wordAtom.pipe(map(() => {
      return (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          perspective: '1000px',
        }}>
          <Box
            className="progress-bar"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: `${progress}%`,
              height: '10px',
              backgroundColor: purple[400],
              transition: 'width 0.3s ease-out',
              zIndex: 1001,
            }}
          />
          {debugMode && (
            <Box
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '5px',
                zIndex: 1000,
              }}
            >
              Card Side: {cardSide}
            </Box>
          )}
          <Box sx={{ position: 'relative', width: '90%', marginTop: 2 }}>
            {isTransitioning && (
              <Card variant="outlined" sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: blueGrey[50],
                zIndex: -1,
                boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.4)',
                overflow: 'hidden',
              }}>
                <CardContent sx={{
                  filter: 'blur(10px)',
                  opacity: 0.7,
                }}>
                  <WordCardSide
                    wordAtom={mockWordAtom}
                    cardSide={0}
                    cardTestMode={false}
                    setCardTestMode={() => {}}
                    cardHandlers={{
                      flipCard: () => {},
                      nextCard: () => {},
                      prevCard: () => {},
                    }}
                  />
                </CardContent>
              </Card>
            )}
            <Card variant="outlined" sx={{
              width: '100%',
              display: 'flex', 
              flexDirection: 'column',
              height: 'calc(100% - 80px)',
              overflow: 'hidden',
              transition: isResetting ? 'none' : 'transform 0.3s ease-out',
              transform: isSwipingLeft 
                ? 'translateX(-120%)' 
                : isSwipingRight 
                  ? 'translateX(120%)' 
                  : 'translateX(0%)',
              boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.4)',
              zIndex: 2,
            }}>
              <CardContent
                sx={{
                  flexGrow: 1, 
                  overflowY: 'auto', 
                  alignItems: 'center', 
                  justifySelf: 'center',
                  position: 'relative',
                }}
                ref={scrolledRef}
              >
                <WordCardSide
                  wordAtom={wordAtom}
                  cardSide={cardSide}
                  cardTestMode={cardTestMode}
                  setCardTestMode={setCardTestMode}
                  cardHandlers={cardHandlers}
                />
              </CardContent>
            </Card>
          </Box>
        </Box>
      );
    }))
  }</F.Fragment>
};

export default WordCard;

import React, { useState } from 'react';
import { Box, CardMedia, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Atom } from '@grammarly/focal';
import { Word } from "../things.dto";

interface PictureDisplayProps {
  word: Word;
  wordAtom: Atom<Word>;
  onTap: () => void;
}

export const PictureDisplay: React.FC<PictureDisplayProps> = ({ word, wordAtom, onTap }) => {
  const [currentPictureIndex, setCurrentPictureIndex] = useState(() => {
    const firstNonRemovedIndex = word.pictures.findIndex(pic => !pic.removed);
    return firstNonRemovedIndex !== -1 ? firstNonRemovedIndex : 0;
  });

  const handlePictureTap = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setCurrentPictureIndex((prevIndex) => (prevIndex + 1) % word.pictures.length);
    // onTap(); // Removed this line
  };

  const currentPicture = word.pictures[currentPictureIndex];

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: 'calc(100% - 100px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <CardMedia
        component="img"
        image={currentPicture.path}
        alt={word.memo ? `ну типу ${word.memo}, поняв?` : `Image for ${word.en}`}
        sx={{
          width: '90%',
          height: '90%',
          objectFit: 'contain',
          opacity: currentPicture.removed ? 0.2 : 1,
          transition: 'opacity 0.3s ease-in-out',
        }}
        onClick={handlePictureTap}
      />
      <Box sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'space-between',
        padding: 1
      }}>
        <Fab
          size="small"
          color={currentPicture.fav ? "secondary" : "primary"}
          onClick={(e) => {
            e.stopPropagation();
            wordAtom.modify(mutate((w) => (w.pictures[currentPictureIndex].fav = !w.pictures[currentPictureIndex].fav)));
          }}
        >
          {currentPicture.fav ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        </Fab>
        <Fab
          size="small"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            wordAtom.modify(mutate((w) => {
              w.pictures[currentPictureIndex].removed = !w.pictures[currentPictureIndex].removed;
            }));
          }}
        >
          {currentPicture.removed ? <AddIcon /> : <RemoveIcon />}
        </Fab>
      </Box>
    </Box>
  );
};

function mutate<T>(fn: (v: NonNullable<T>) => void) {
  return (v: T) => {
    let v1 = JSON.parse(JSON.stringify(v!));
    fn(v1);
    return v1;
  }
}